import Head from 'next/head'
import configuration from '../../../config'
import { Link } from '../../atoms/Link'
import { theme } from '../../../styles'
import { PaginationLink } from './PaginationLink'
import { SVGIcon } from '../../atoms/SVGIcon'

export interface Element {
  page: number
  link: string
  hasActive?: boolean
}

export interface IPagination {
  first: Element
  last: Element
  main: Element[]
  next?: Element
  prev?: Element
}

export function Pagination({ first, last, main, next, prev }: IPagination): JSX.Element {
  return (
    <>
      <Head>
        {prev && <link rel="prev" href={`${configuration.domain}${prev.link}`} />}
        {next && <link rel="next" href={`${configuration.domain}${next.link}`} />}
      </Head>

      <nav role="navigation" aria-label="Pagination">
        <ul className="Pagination">
          {prev && (
            <li>
              <Link url={prev?.link} title="Page précédente">
                <SVGIcon
                  name="chevron"
                  orientation="left"
                  aria-hidden="true"
                  primaryColor={theme.cssVars.fontColor}
                  focusable="false"
                />
              </Link>
            </li>
          )}

          <li>
            <PaginationLink elem={first} />
          </li>

          {main.map((elem) => (
            <li key={`pagination_${elem.page}`}>
              <PaginationLink key={elem.page} elem={elem} />
            </li>
          ))}

          <li>
            <PaginationLink elem={last} />
          </li>

          {next && (
            <li>
              <Link url={next?.link} title="Page suivante">
                <SVGIcon
                  name="chevron"
                  aria-hidden="true"
                  primaryColor={theme.cssVars.fontColor}
                  focusable="false"
                />
              </Link>
            </li>
          )}
        </ul>
      </nav>

      <style jsx>{`
        .Pagination {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          padding: ${theme.block.marginLR}px;
          margin: 0 auto 89px;
          list-style: none;
        }

        .Pagination :global(a),
        .Pagination :global(span) {
          align-items: center;
          border-radius: 24px;
          border: solid 1px #b9bebe;
          color: ${theme.cssVars.fontColor};
          display: inline-flex;
          height: 35px;
          justify-content: center;
          margin: 5px;
          padding: 2px 14px 0 14px;
        }

        .Pagination :global(span) {
          background: ${theme.cssVars.focusBlue};
        }
      `}</style>
    </>
  )
}
